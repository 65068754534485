import { Link } from 'react-router-dom';

const ActiveThemeCard = (props) => {
	const { key, theme } = props;
	return (
		<div
			key={key}
			className="mt-1 relative overflow-hidden group bg-gradient-to-r from-gray-800 to-gray-900 p-4 rounded-xl flex items-end space-x-2"
		>
			<img
				src={theme.image}
				alt={theme.name}
				className="w-20 h-20 rounded-lg object-cover"
			/>
			<div className="flex flex-col items-start text-white">
				<div className="text-lg font-medium">{theme.name}</div>
				<div className="flex divide-x divide-gray-400 space-x-2 text-xs mb-1 text-gray-300">
					<p>{theme.version}</p>
					{theme.author && <p className="pl-2">{theme.author}</p>}
					{theme?.isDefault && <p className="pl-2">Default theme </p>}
				</div>
			</div>
			<svg
				viewBox="0 0 1024 1024"
				className="absolute left-1/2 top-1/2 -z-10 h-92 w-92 -translate-x-1/2 filter blur-2xl opacity-50"
				aria-hidden="true"
			>
				<circle
					cx={512}
					cy={512}
					r={512}
					fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
					fillOpacity="0.7"
				/>
				<defs>
					<radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
						<stop stopColor="#7775D6" />
						<stop offset={1} stopColor="#E935C1" />
					</radialGradient>
				</defs>
			</svg>
		</div>
	);
};

const ActiveThemeCardWrapper = (props) => {
	const { getRouterPath = () => {} } = props;
	if (props?.theme?.handle)
		return (
			<Link
				to={getRouterPath('/themes/' + props?.theme?.handle)}
				key={props?.theme?.key}
			>
				<ActiveThemeCard {...props} />
			</Link>
		);
	return <ActiveThemeCard {...props} />;
};
export default ActiveThemeCardWrapper;
