import React, { useState } from 'react';
import { CogIcon, CheckCircleIcon } from '@heroicons/react/solid';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import { FileUpload } from './components';
import Input from '@appmaker/core/components/AppmakerForm/components/TextField';
import api from '../../../../api';
import { useFormData } from '../../../../store';
import { useProject } from '@appmaker/core/hooks';
import { useHistory } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';

const sendNotification = (toastMessage, options = {}, otherOption = {}) => {
	const toastOption = {
		duration: 5000,
		position: 'top-right',
	};
	switch (options.appearance) {
		case 'error': {
			return toast.error(toastMessage, { ...toastOption, ...otherOption });
		}
		case 'success': {
			return toast.success(toastMessage, toastOption);
		}
		default: {
			return toast(toastMessage, toastOption);
		}
	}
};

const SuccessState = ({ onRedirect }) => {
	return (
		<div className="flex flex-col items-center justify-center p-8 text-center">
			<CheckCircleIcon className="h-16 w-16 text-green-500 mb-4" />
			<h2 className="text-2xl font-semibold text-gray-900 mb-2">
				Upload Completed!
			</h2>
			<p className="text-gray-600 mb-6">
				Your configuration has been successfully uploaded.
			</p>
			<button
				onClick={onRedirect}
				className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
			>
				Go to App Builds
			</button>
		</div>
	);
};

const UploadP8 = () => {
	const [formData, setFormData] = useState({
		p8File: null,
		authKey: false,
		keyId: '',
		issuerId: '',
	});
	const [isSuccess, setIsSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const getRouterPath = useRouterPath();

	const isFormValid = formData.authKey && formData.apiKey && formData.issuerId;
	const { iosAppData = {} } = useFormData();
	const { id: appId } = iosAppData;
	const { projectId } = useProject();

	const handleFileUpload = (value, file) => {
		setFormData((prev) => ({
			...prev,
			authKey: value,
			p8File: file,
		}));
	};

	const handleInputChange = (field) => (value) => {
		setFormData((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const buildIpa = async () => {
		if (!isFormValid) {
			sendNotification('Please fill all the fields', {
				appearance: 'error',
			});
			return;
		}
		setIsLoading(true);
		try {
			const response = await api.uploadIosAuthKey({
				projectId: projectId,
				appId,
				apiKey: formData.apiKey,
				authKey: formData.authKey,
				issuerId: formData.issuerId,
				file: formData.p8File,
			});
			if (response && response.status) {
				sendNotification('Config uploaded!', {
					appearance: 'success',
				});
				setIsSuccess(true);
			} else {
				sendNotification(
					'Something went wrong',
					{
						appearance: 'error',
					},
					{ duration: 20000 }
				);
			}
		} catch (error) {
			sendNotification(
				'Something went wrong',
				{
					appearance: 'error',
				},
				{ duration: 20000 }
			);
		} finally {
			setIsLoading(false);
		}
	};

	const handleRedirect = () => {
		history.push(getRouterPath(`/app-builds`));
	};

	// if (isLoading) {
	// 	return (
	// 		<div className="flex flex-col flex-auto items-center justify-center">
	// 			<CogIcon className="h-20 w-20 animate-spin-slow text-blue-900" />
	// 		</div>
	// 	);
	// }

	if (isSuccess) {
		return <SuccessState onRedirect={handleRedirect} />;
	}

	return (
		<div className="p-6">
			<ul className="list-outside ml-4 list-disc flex flex-col gap-3">
				<Toaster position="top-right" reverseOrder={true} />

				<li>
					Upload .p8 File
					<FileUpload
						properties={{
							fileType: '.p8',
							disabled: isLoading,
						}}
						onDrop={handleFileUpload}
					/>
				</li>
				<li>
					<Input
						properties={{
							label: 'Enter Key ID',
							inputProps: {
								disabled: isLoading,
							},
						}}
						onChange={handleInputChange('apiKey')}
					/>
				</li>
				<li>
					<Input
						properties={{
							label: 'Issurer ID',
							inputProps: {
								disabled: isLoading,
							},
						}}
						onChange={handleInputChange('issuerId')}
					/>
				</li>
				<li className="list-none mt-4">
					<button
						onClick={buildIpa}
						type="button"
						disabled={!isFormValid || isLoading}
						className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
							${
								isFormValid && !isLoading
									? 'bg-indigo-600 hover:bg-indigo-700'
									: 'bg-gray-400 cursor-not-allowed'
							} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
					>
						{isLoading ? (
							<>
								<div className="animate-spin -ml-1 mr-2 h-5 w-5 border-t-2 border-b-2 border-white rounded-full" />
								Uploading...
							</>
						) : (
							<>
								<CogIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
								Upload Config
							</>
						)}
					</button>
				</li>
			</ul>
		</div>
	);
};

export default UploadP8;
