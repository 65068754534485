import React from 'react';
import { CheckCircleIcon, RefreshIcon } from '@heroicons/react/solid';

const ConfigUploadSuccess = ({ onReupload = () => {} }) => {
	return (
		<div className="flex flex-col items-center justify-center p-8 text-center">
			<CheckCircleIcon className="h-16 w-16 text-green-500 mb-4" />
			<h2 className="text-2xl font-semibold text-gray-900 mb-2">
				Config Already Uploaded
			</h2>
			<p className="text-gray-600 mb-6">
				Your configuration has already been uploaded to the App Store.
			</p>
			<div className="flex gap-4">
				<button
					onClick={onReupload}
					className="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					<RefreshIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
					Upload New Config
				</button>
			</div>
		</div>
	);
};

export default ConfigUploadSuccess;
