import { useState } from 'react';
import api from '../api';
import { getProjectId } from '@appmaker/core/store/project';

const OneTimePayment = () => {
	const projectId = getProjectId();
	const [formData, setFormData] = useState({
		name: 'One Time Payment',
		amount: '1000',
	});
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [copied, setCopied] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);
		setResponse(null);

		try {
			const result = await api.oneTimePayment({
				projectId,
				data: {
					name: formData.name,
					amount: String(Math.round(Number(formData.amount) * 1)),
				},
			});
			setResponse(result.data);
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setLoading(false);
		}
	};

	const handleCopy = () => {
		navigator.clipboard.writeText(response.confirmationLink.confirmationUrl);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 2000); // Reset after 2 seconds
	};

	return (
		<div className="p-4">
			<h1 className="text-2xl mb-4">Generate One-Time Payment Link</h1>

			<form onSubmit={handleSubmit} className="mb-4">
				<div className="flex flex-col gap-4 max-w-md">
					<div>
						<label className="block mb-2">
							Name
							<input
								type="text"
								name="name"
								value={formData.name}
								onChange={handleChange}
								required
								className="block w-full border p-2 mt-1"
							/>
						</label>
					</div>

					<div>
						<label className="block mb-2">
							Amount (USD)
							<input
								type="number"
								name="amount"
								value={formData.amount}
								onChange={handleChange}
								required
								step="0.01"
								min="0"
								className="block w-full border p-2 mt-1"
							/>
						</label>
						<span className="text-sm text-gray-600">
							Amount in USD (e.g., 1000 for $1,000.00)
						</span>
					</div>

					<button
						type="submit"
						disabled={loading}
						className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
					>
						{loading ? 'Generating...' : 'Generate Payment Link'}
					</button>
				</div>
			</form>

			{error && (
				<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
					{error}
				</div>
			)}

			{response && (
				<div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
					<p className="font-bold mb-2">Payment link generated successfully!</p>
					<div className="space-y-2">
						<p>Name: {response.name}</p>
						<p>Amount: ${Number(response.amount)}</p>
						<div className="mt-4">
							<p className="font-medium mb-1">Confirmation Link:</p>
							<div className="flex gap-2 items-start">
								<input
									type="text"
									readOnly
									value={response.confirmationLink.confirmationUrl}
									className="flex-1 p-2 bg-white border rounded text-sm text-gray-700"
									onClick={(e) => e.target.select()}
								/>
								<button
									onClick={handleCopy}
									className={`px-3 py-2 text-white rounded transition-colors ${
										copied
											? 'bg-green-500 hover:bg-green-600'
											: 'bg-blue-500 hover:bg-blue-600'
									}`}
								>
									{copied ? 'Copied!' : 'Copy'}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default OneTimePayment;
